import Vue from 'vue'
import App from './App.vue'
import '@/config/registerServiceWorker'
import pkg from '../package'
import setupRouter from '@/config/router'
import store from './store'
import axios from '@/config/axios'
import axiosPush from '@/config/axiosPush'
import socket from '@/config/socket'
import { i18n, asyncInitLang } from '@/config/i18n-setup'
import moment from 'moment'
import { generateDeviceUUID } from '@/utils/utils.js'
import VueGtm from '@gtm-support/vue2-gtm'
import '@/assets/css/var.less'
import '@/assets/css/main.scss'
import 'tippy.js/dist/tippy.css'

// Vue2TouchEvents
import Vue2TouchEvents from 'vue2-touch-events'
Vue.use(Vue2TouchEvents, {
    disableClick: true,
    touchClass: 'touch',
    tapTolerance: 10,
    touchHoldTolerance: 400,
    swipeTolerance: 30,
    longTapTimeInterval: 400,
    namespace: 'touch'
})

// VueSocketIOExt
import VueSocketIOExt from 'vue-socket.io-extended'
Vue.use(VueSocketIOExt, socket)

// VueCookies
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

// VueTippy
import VueTippy from "vue-tippy"
Vue.use(VueTippy)

// VueMeta
import VueMeta from 'vue-meta'
Vue.use(VueMeta, { refreshOnceOnNavigation: true })

// lightgallery
import lightgallery from "lightgallery.js"
import "lightgallery.js/dist/css/lightgallery.min.css"
import "lg-zoom.js"
import "lg-thumbnail.js"
import "lg-rotate.js"
Vue.use(lightgallery)

//Excel Export Vue
// import excel from 'vue-excel-export'
// Vue.use(excel)


// Profiler
import Profiler from '@apps/Profiler'
Vue.use(Profiler)

import VueProgressBar from 'vue-progressbar'
Vue.use(VueProgressBar, {
    color: "#1d65c0",
    failedColor: "#ff4d4e",
    thickness: "2px",
    autoRevert: true,
    location: "top",
    inverse: false
})

// VueAnt
import {
    Empty,
    Popconfirm,
    Descriptions,
    message,
    Layout,
    Menu,
    Timeline,
    Collapse,
    notification,
    Result,
    Icon,
    Tooltip,
    Table,
    Tag,
    Rate,
    Tabs,
    Spin,
    Divider,
    Dropdown,
    Radio,
    Badge,
    Select,
    Statistic,
    DatePicker,
    Popover,
    Input,
    Modal,
    Form,
    FormModel,
    Upload,
    TreeSelect,
    Card,
    Alert,
    Switch,
    Calendar,
    Row,
    Col,
    Pagination,
    TimePicker,
    Skeleton,
    ConfigProvider,
    InputNumber,
    Carousel,
    Progress,
    Transfer,
    AutoComplete,
    List,
    Tree,
    Breadcrumb
} from 'ant-design-vue'
import Avatar from '@/components/antDesign/avatar'
import Drawer from '@/components/antDesign/drawer'
import Button from '@/components/antDesign/button'
import Checkbox from '@/components/antDesign/checkbox'

Vue.use(Statistic)
Vue.use(Breadcrumb)
Vue.use(Tree)
Vue.use(Row)
Vue.use(Col)
Vue.use(Timeline)
Vue.use(Button)
Vue.use(Layout)
Vue.use(Menu)
Vue.use(Collapse)
Vue.use(Icon)
Vue.use(Table)
Vue.use(Tag)
Vue.use(Divider)
Vue.use(Dropdown)
Vue.use(Avatar)
Vue.use(Badge)
Vue.use(Select)
Vue.use(DatePicker)
Vue.use(Popover)
Vue.use(Input)
Vue.use(Modal)
Vue.use(Form)
Vue.use(FormModel)
Vue.use(Upload)
Vue.use(TreeSelect)
Vue.use(Alert)
Vue.use(Switch)
Vue.use(Card)
Vue.use(Tabs)
Vue.use(Radio)
Vue.use(Spin)
Vue.use(Tooltip)
Vue.use(Rate)
Vue.use(Pagination)
Vue.use(TimePicker)
Vue.use(Checkbox)
Vue.use(InputNumber)
Vue.use(Drawer)
Vue.use(Descriptions)
Vue.use(Popconfirm)
Vue.use(Empty)
Vue.use(Carousel)
Vue.use(Skeleton)
Vue.use(Calendar)
Vue.use(Transfer)
Vue.use(ConfigProvider)
Vue.use(Progress)
Vue.use(AutoComplete)
Vue.use(List)
Vue.use(Result)
Vue.prototype.$moment = moment
Vue.prototype.$message = message
Vue.prototype.$notification = notification
Vue.prototype.$error = Modal.error
Vue.prototype.$confirm = Modal.confirm
Vue.prototype.$success = Modal.success
Vue.config.productionTip = false
Vue.prototype.$http = axios
Vue.prototype.$http2 = axiosPush

Object.filter = function (obj, filtercheck) {
    let result = {}
    Object.keys(obj).forEach((key) => { if (filtercheck(obj[key])) result[key] = obj[key] })
    return result
}

const mainAppInit = async () => {
    try {
        const user = localStorage.getItem('user')

        await asyncInitLang()
        generateDeviceUUID()

        if (user) {
            await store.dispatch('getCacheUID')
            const config = await store.dispatch('configInit')
            // await store.dispatch('appInit')
            await store.dispatch('navigation/routeInit')

            const primaryColor = config?.theme?.main_color || process.env.VUE_APP_MAIN_COLOR
            store.commit("config/SET_PRIMARY_COLOR", primaryColor)

            /*if(config?.site_setting?.pwa) {
                setupSW()
            }*/
        } else
            store.commit("config/SET_PRIMARY_COLOR", process.env.VUE_APP_MAIN_COLOR)

        // Vue.prototype.$i18nRoute = Trans.i18nRoute.bind(Trans)
        const router = setupRouter({ store, axios, VueCookies, prototype: Vue.prototype })

        Vue.use(VueGtm, {
            id: 'GTM-WR54QKMN', // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
            defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
            compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
            enabled: process.env.NODE_ENV === 'production' ? true : false, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
            debug: false, // Whether or not display console logs debugs (optional)
            loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
            vueRouter: router, // Pass the router instance to automatically sync with router (optional)
            trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
        })

        // if (process.env.NODE_ENV !== 'dev') {
        //     Sentry.init({
        //         Vue,
        //         dsn: process.env.VUE_APP_SENTRY_DSN,
        //         integrations: [
        //             new BrowserTracing({
        //                 routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        //                 tracingOrigins: ["localhost", "bpms.gos24.kz", "bkz.centersoft.kz", /^\//],
        //             }),
        //         ],
        //         release: `${pkg.name}@${pkg.version}`,
        //         debug: false,
        //         tracesSampleRate: 1.0
        //     })
        // }

        console.log(`%c ${pkg.name} version ${pkg.version} ${process.env.NODE_ENV} init 👍`, 'color: #bada55')

        new Vue({
            i18n,
            router,
            store,
            mounted () {
                const storeUser = JSON.parse(user)

                let params = storeUser?.id ? `?user=${storeUser.id}` : ''

                const script = document.createElement('script')
                script.async = true
                script.type = 'text/javascript'
                script.src = `/api/v1/app_info/custom_js/${params}`

                script.onload = () => {
                    
                }

                script.onerror = () => {
                    console.log('%c Error occurred while loading script', 'color: #bf1432')
                }

                document.body.appendChild(script)
            },
            render: h => h(App)
        }).$mount('#app')
    } catch (e) {
        console.log(e, 'mainAppInit')
    }
}

mainAppInit()